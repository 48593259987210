<template>
    <section class="mx-auto max-w-md px-3 pb-5 pt-24">
        <slot v-if="state.mounted" />
    </section>

    <div
        class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white"
        v-if="state.loading"
    >
        <span
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        >
            <base-loading size="lg" />
        </span>
    </div>
</template>

<script setup>
const emitter = useEmitter()

const state = reactive({
    loading: false,
    mounted: false,
})

onMounted(() => {
    emitter.on('set-loading', setLoading)
    state.mounted = true
})

onBeforeUnmount(() => {
    emitter.off('set-loading', setLoading)
    state.mounted = false
})

const setLoading = (value) => {
    state.loading = value
}
</script>
